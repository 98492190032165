import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// firebase init
const firebaseConfig = {
  apiKey: 'AIzaSyC7lGXiD0DN9h6hA_ZY2cEYiOET_C2q57k',
  authDomain: 'you-up-app.web.app',
  databaseURL: 'https://you-up-app.firebaseio.com',
  projectId: 'you-up-app',
  storageBucket: 'you-up-app.appspot.com',
  messagingSenderId: '',
  appId: '1:211553034077:web:1dfc8ab0ff422ac3b152b4',
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();

// collection references
const usersCollection = db.collection('users');
const postsCollection = db.collection('posts');
const commentsCollection = db.collection('comments');
const likesCollection = db.collection('likes');

// export utils/refs
export {
  db, auth, usersCollection, postsCollection, commentsCollection, likesCollection,
};
